import * as React from "react";
import { FormCheck } from "react-bootstrap";
import { InputBaseProps } from "..";
import { Option } from "../../../../../../server/lib/option/option";

export type RadiosProps = {
	checkOff?: boolean;
	options: Option[];
	checked: Option["value"];
	onChange: (checkeds: Option["value"]) => void;
	inline?: boolean;
} & InputBaseProps;

export const Radios = React.memo((props: RadiosProps) => {
	const { checked, onChange, options, checkOff, isInvalid, ...radiosProps } = props;
	const onChangeRadio = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, value: Option["value"]) => {
			if (e.target.checked) {
				onChange(value);
			}
		},
		[onChange]
	);
	const onClick = React.useCallback(
		(value: Option["value"]) => {
			if (checkOff && value === checked) {
				onChange(undefined);
			}
		},
		[onChange, checkOff, checked]
	);
	return (
		<span style={isInvalid ? { color: "red" } : {}}>
			{options.map((option) => (
				<FormCheck
					{...radiosProps}
					type={"radio"}
					key={`checkbox-${option.value}`}
					label={option.label}
					value={option.value.toString()}
					checked={checked === option.value}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeRadio(e, option.value)}
					onClick={() => onClick(option.value)}
					style={radiosProps.inline ? { paddingRight: "10px" } : {}}
				/>
			))}
		</span>
	);
});
