import { BasicAuthParams } from "../../components/pages/basicAuth";
import { BasicAuthAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";

export type State = BasicAuthParams & {
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	id: "",
	password: "",
	authentication: {
		status: 0,
		isSuccess: false,
	},
	info: { message: "", isSuccess: true },
	loading: true,
};

export const reducer = (state: State, action: BasicAuthAction): State => {
	switch (action.type) {
		case "change":
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "changeAuthentication":
			return {
				...state,
				authentication: {
					status: action.payload.status,
					isSuccess: action.payload.auth,
				},
			};
		default:
			return state;
	}
};
