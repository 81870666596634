import * as React from "react";
import { FormControl } from "react-bootstrap";
import { InputBaseProps } from "..";
import { Option } from "../../../../../../server/lib/option/option";

export type SingleSelectProps = {
	value: any;
	options: Option[];
	noSelectLabel?: string;
	onChange: (value: Option["value"]) => void;
} & InputBaseProps;

export const SingleSelect = React.memo((props: SingleSelectProps) => {
	const { options, onChange, value, noSelectLabel = "▼", ...selectProps } = props;
	const onChangeSelect = React.useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			const value = e.target.value;
			onChange(value || undefined);
		},
		[onChange]
	);
	return (
		<FormControl as={"select"} onChange={onChangeSelect} {...selectProps} value={value}>
			<option value={""}>{noSelectLabel}</option>
			{options.map((option) => (
				<option key={`${name}-option-${option.value}`} value={option.value}>
					{option.label}
				</option>
			))}
		</FormControl>
	);
});
