import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { BasicAuthContainer } from "../containers/basicAuth";
import { endpoint } from "./endpoint";

export const Routes = (): JSX.Element => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path={`${endpoint.basicAuth}`}>
					<BasicAuthContainer />
				</Route>
				<Route>
					<ErrorPage text="Not Found" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};
