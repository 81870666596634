export const internalApiEndpoint = {
	login: "/api/login",
	authentication: "/api/auth",
	logout: "/api/logout",
	account: "/api/account",

	basicAuth: "/api/basic-auth",
	download: "/api/download",
} as const;

export type InternalApiEndpoint = keyof typeof internalApiEndpoint;
