import * as React from "react";
import Select from "react-select";
import { InputBaseProps } from "..";
import { Option } from "../../../../../../server/lib/option/option";

export type MultipleSelectProps = {
	selecteds: any[];
	onChange: (value: any[]) => void;
	options: Option[];
} & InputBaseProps;

export const MultipleSelect = React.memo((props: MultipleSelectProps) => {
	const { selecteds, onChange, options, ...mSelectProps } = props;
	const selected = React.useMemo(() => {
		if (!selecteds) return [];
		return options.filter((c) => selecteds.includes(c.value));
	}, [selecteds, options]);
	const onChangeSelect = React.useCallback(
		(selected?: Option[]) => {
			onChange(selected ? selected.map((s) => s.value) : []);
		},
		[onChange]
	);
	return <Select isMulti value={selected} onChange={onChangeSelect} options={options} {...mSelectProps} />;
});
