import * as React from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { InputBaseProps } from "..";

export type TextProps = {
	onChange: (value: string) => void;
	value: string;
	type?: FormControlProps["type"];
	placeholder?: string;
} & InputBaseProps;

export const Text = React.memo((props: TextProps) => {
	const { onChange, value, ...textProps } = props;
	const onChangeDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [
		onChange,
	]);
	return <FormControl {...textProps} value={value || ""} onChange={onChangeDate} />;
});
