import * as React from "react";
import { FormCheck } from "react-bootstrap";
import { InputBaseProps } from "..";
import { Option } from "../../../../../../server/lib/option/option";

export type CheckboxesProps = {
	options: Option[];
	checkeds: any[];
	onChange: (checkeds: Option["value"][]) => void;
	exclusive?: Option["value"][];
	inline?: boolean;
} & InputBaseProps;

export const Checkboxes = React.memo((props: CheckboxesProps) => {
	const { checkeds, onChange, options, exclusive, isInvalid, ...checkboxProps } = props;
	const onChangeCheckbox = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, value: Option["value"]) => {
			const targetChecked = e.target.checked;
			if (targetChecked) {
				if (exclusive && exclusive.indexOf(value) !== -1) {
					onChange([value]);
				} else {
					const target = checkeds.concat(value);
					onChange(exclusive ? target.filter((v) => exclusive.indexOf(v) === -1) : target);
				}
			} else {
				onChange(checkeds.filter((ch) => ch !== value));
			}
		},
		[checkeds, onChange, exclusive]
	);
	return (
		<span style={isInvalid ? { color: "red" } : {}}>
			{options.map((option) => (
				<FormCheck
					{...checkboxProps}
					type={"checkbox"}
					key={`checkbox-${option.value}`}
					label={option.label}
					value={option.value.toString()}
					checked={checkeds.includes(option.value)}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheckbox(e, option.value)}
					style={checkboxProps.inline ? { paddingRight: "10px" } : {}}
				/>
			))}
		</span>
	);
});
