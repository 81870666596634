import * as React from "react";
import { ErrorObject } from "@lu/validator";
import { Col, Row, Card, Button, Alert } from "react-bootstrap";
import { ValidationInput } from "../../parts/validation-input";
import { getErrorText } from "../../../lib/error";

export type BasicAuthParams = {
	id: string;
	password: string;
	authentication: { status: number; isSuccess: boolean };
};
export type BasicAuthPageProps = BasicAuthParams & {
	onChange: (name: keyof BasicAuthParams, value: string) => void;
	onSubmit: () => void;
	handleDownload: (id: string) => void;
	errorMessage?: string;
	errors?: ErrorObject;
};

export const BasicAuthPage = React.memo((props: BasicAuthPageProps) => {
	const { errorMessage, errors, onChange, onSubmit, id, password, authentication, handleDownload } = props;
	const onChangeEmail = React.useCallback((value: string) => onChange("id", value), [onChange]);
	const onChangePassword = React.useCallback((value: string) => onChange("password", value), [onChange]);
	const submitDisabled = React.useMemo(() => !id || !password, [id, password]);
	const authDisable = React.useMemo(() => !authentication.isSuccess, [authentication.isSuccess]);

	return (
		<Row style={{ marginTop: 100, marginBottom: 10 }}>
			<Col md={{ span: 4, offset: 4 }}>
				<Alert show={authentication.status !== 0} variant="secondary">
					{authentication.status !== 200 && (
						<p>
							idもしくはpasswordに誤りがあります。 <br />
							ご不明な場合はGCNJ_SDGs実態調査窓口までお問合せください。
						</p>
					)}
					{authentication.status === 200 && (
						<p>
							認証に成功しました。
							<br />
							資料のダウンロードが可能です。
						</p>
					)}
				</Alert>
				<Card>
					<Card.Body>
						{errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
						<ValidationInput
							inputtype={"text"}
							label={"id"}
							placeholder="****@example.com"
							name={"id"}
							value={id}
							onChange={onChangeEmail}
							error={getErrorText(errors, "id")}
						/>
						<ValidationInput
							label={"password"}
							inputtype={"password"}
							name={"password"}
							value={password}
							onChange={onChangePassword}
							error={getErrorText(errors, "password")}
						/>
						<Button variant={"secondary"} disabled={submitDisabled} onClick={onSubmit} block>
							認証する
						</Button>
					</Card.Body>
				</Card>
				<Card style={{ marginTop: 10 }}>
					<Card.Title style={{ marginTop: 10, marginLeft: 10 }}>
						認証に成功した場合、資料をダウンロードできます。
					</Card.Title>
					<Card.Body>
						<Button variant={"secondary"} disabled={authDisable} onClick={() => handleDownload(password)} block>
							資料をダウンロードする
						</Button>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
});
