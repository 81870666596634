import * as React from "react";
import { FormControl } from "react-bootstrap";
import { Option } from "../../../../../../server/lib/option/option";
import { SingleSelect } from "..";
import { ErrorObject } from "@lu/validator";
import { getErrorText } from "../../../../lib/error";
import classnames from "classnames";

export type RangeValue = {
	from: number;
	to: number;
};

export type NumberOption = Omit<Option, "value"> & { value: number };
export type RangeSelectProps = {
	name: string;
	value: RangeValue;
	onChange: (value: RangeValue) => void;
	fromOptions: NumberOption[];
	toOptions: NumberOption[];
	errors?: ErrorObject;
};

export const RangeSelect = React.memo((props: RangeSelectProps) => {
	const { fromOptions, toOptions, value, name, onChange, errors } = props;
	const filteredFromOption = React.useMemo(() => {
		return fromOptions.filter((option) => {
			return option.value <= (value.to || 10000000);
		});
	}, [value, fromOptions]);
	const filteredToOption = React.useMemo(() => {
		return toOptions.filter((option) => {
			return option.value >= (value.from || -1);
		});
	}, [value, toOptions]);
	const onChangeValue = React.useCallback(
		(name: keyof RangeValue, v: any) => {
			const tmp = Number(v);
			return onChange({ ...value, [name]: isNaN(tmp) ? undefined : tmp });
		},
		[onChange, value]
	);
	const fromError = React.useMemo(() => getErrorText(errors, "from"), [errors]);
	const toError = React.useMemo(() => getErrorText(errors, "to"), [errors]);
	return (
		<div className={classnames("range-select")}>
			<div className={classnames("range-select__from")}>
				<SingleSelect
					options={filteredFromOption}
					value={value.from}
					name={`${name}-from`}
					onChange={(v) => onChangeValue("from", v)}
					isInvalid={!!fromError}
				/>
				{!!fromError && (
					<FormControl.Feedback type={"invalid"} style={{ display: "block" }}>
						{fromError}
					</FormControl.Feedback>
				)}
			</div>
			<div className={classnames("range-select__kara")} style={{ margin: "0.3rem 0" }}>
				〜
			</div>
			<div className={classnames("range-select__to")}>
				<SingleSelect
					options={filteredToOption}
					value={value.to}
					name={`${name}-to`}
					onChange={(v) => onChangeValue("to", v)}
					isInvalid={!!toError}
				/>
				{!!toError && (
					<FormControl.Feedback type={"invalid"} style={{ display: "block" }}>
						{toError}
					</FormControl.Feedback>
				)}
			</div>
		</div>
	);
});
