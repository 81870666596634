import * as React from "react";
import classNames from "classnames";
import { internalApiEndpoint } from "../../../../server/router/api/internal/endpoint";
import { BasicAuthResponse } from "../../../../server/router/router";
import { BasicAuthPage, BasicAuthParams } from "../../components/pages/basicAuth";
import { AppBar } from "../../components/parts/app-bar";
import { makeError, post, fileDownload } from "../../lib/request";
import { initState, reducer } from "../../reducers/basicAuth";

export const BasicAuthContainer = (): JSX.Element => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, id, password, authentication } = state;
	const onChange = React.useCallback(
		(name: keyof BasicAuthParams, value: string) => dispatch({ type: "change", payload: { name, value } }),
		[]
	);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<BasicAuthResponse>(internalApiEndpoint.basicAuth, { id, password })
			.then((response) => {
				dispatch({ type: "changeAuthentication", payload: response.data });
			})
			.catch((error) => {
				console.log("認証失敗");
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [id, password]);
	const handleDownload = React.useCallback((id: string) => {
		fileDownload(`${internalApiEndpoint.download}/${id}`, `${id}-reports.zip`).catch((error) => {
			dispatch({ type: "changeMessageInfo", payload: makeError(error) });
		});
	}, []);
	console.log(info);
	return (
		<div className={classNames("body-container")}>
			<AppBar />
			<BasicAuthPage
				id={id}
				password={password}
				authentication={authentication}
				errorMessage={info.isSuccess ? undefined : info.message}
				errors={info.errors}
				onChange={onChange}
				onSubmit={onSubmit}
				handleDownload={handleDownload}
			/>
		</div>
	);
};
