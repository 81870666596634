import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../../server/types/request/error";
import { MessageInfo } from "../types/info";
import * as json2csv from "json2csv";

const meta = document.querySelector('meta[name="csrf-token"]');
if (meta) {
	axios.defaults.headers.common = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRF-TOKEN": meta.getAttribute("content"),
	};
}

export const array2CsvBlob = (input: (string | number | boolean)[][]): Blob => {
	const json2csvParser = new json2csv.Parser({ header: false });
	const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	const blob = new Blob([bom, json2csvParser.parse(input)], { type: "text/csv" });
	return blob;
};

export const downloadData = (blob: Blob, filename: string): void => {
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const makeError = (error: AxiosError<ErrorResponse>, expandObjects?: boolean): MessageInfo => {
	if (error && error.response && error.response.data) {
		const { message, errors } = error.response.data;
		if (expandObjects) {
			return {
				message: message + (errors ? "\n" + Object.values(errors).join("\n") : ""),
				isSuccess: false,
			};
		}
		return { message, errors, isSuccess: false };
	}
	return {
		message: "通信エラーが発生しました。ページをリロードしてください",
		isSuccess: false,
	};
};

export const post = async <T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.post<T>(url, data, options);
};
export const postFormData = async <T>(
	url: string,
	data: FormData,
	options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
	data.append("_csrf", meta.getAttribute("content"));
	return await axios.post<T>(url, data, {
		...(options ? options : {}),
		headers: {
			"content-type": "multipart/form-data",
		},
	});
};

export const makeQueies = (params: { [key: string]: any }): string => {
	return Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join("&");
};
export const get = async <T>(
	url: string,
	params?: { [key: string]: any },
	options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
	// IE対応。getだとcacheされるので適当なrandを入れる。
	const queries = "?" + makeQueies({ ...params, c: Math.random() });
	return await axios.get<T>(`${url}${queries}`, options);
};
export const put = async <T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.put<T>(url, data, options);
};
export const remove = async <T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.delete<T>(url, options);
};

export const fileDownload = async (url: string, filename: string): Promise<void> => {
	const response = await axios.get(url, { responseType: "blob" });
	downloadData(new Blob([response.data]), filename);
};

const getFileName = (contentDisposition: string): string => {
	const matches = contentDisposition.match(/attachment; filename=(.+\..+)$/);
	if (matches) {
		return matches[1];
	}
	return undefined;
};

export const fileDownloadStream = async (url: string, defaultName?: string): Promise<void> => {
	const response = await axios.get(url, { responseType: "blob" });
	const blob = new Blob([response.data], {
		type: response.data.type,
	});
	const filename = getFileName(response.headers["content-disposition"]) || defaultName;
	downloadData(blob, filename);
};
