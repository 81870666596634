import * as React from "react";
import { Col, FormGroup, FormLabel, Row, FormControl } from "react-bootstrap";
import {
	Checkboxes,
	CheckboxesProps,
	Radios,
	RadiosProps,
	DateInput,
	DateInputProps,
	Text,
	TextProps,
	Textarea,
	TextareaProps,
	Password,
	PasswordProps,
	SingleSelect,
	SingleSelectProps,
	NumberInput,
	NumberInputProps,
	MultipleSelect,
	MultipleSelectProps,
	RangeSelect,
	RangeSelectProps,
	MultipleTextProps,
	MultipleText,
} from "../input";

export type InputType =
	| "checkboxes"
	| "radios"
	| "date"
	| "text"
	| "textarea"
	| "password"
	| "single-select"
	| "number"
	| "multiple-select"
	| "multiple-text"
	| "range-select";

type BaseInputGroup<T extends InputType> = {
	inputtype: T;
	label?: string;
	error?: string;
	noblock?: boolean;
};

export type ValidationCheckboxesProps = BaseInputGroup<"checkboxes"> & CheckboxesProps;
export type ValidationRadiosProps = BaseInputGroup<"radios"> & RadiosProps;
export type ValidationDateProps = BaseInputGroup<"date"> & DateInputProps;
export type ValidationTextProps = BaseInputGroup<"text"> & TextProps;
export type ValidationTextareaProps = BaseInputGroup<"textarea"> & TextareaProps;
export type ValidationPasswordProps = BaseInputGroup<"password"> & PasswordProps;
export type ValidationSingleSelectProps = BaseInputGroup<"single-select"> & SingleSelectProps;
export type ValidationNumberProps = BaseInputGroup<"number"> & NumberInputProps;
export type ValidationMultipleSelectProps = BaseInputGroup<"multiple-select"> & MultipleSelectProps;
export type ValidationMultipleTextProps = BaseInputGroup<"multiple-text"> & MultipleTextProps;
export type ValidationRangeSelectProps = BaseInputGroup<"range-select"> & RangeSelectProps;

export type ValidationInputProps =
	| ValidationRadiosProps
	| ValidationCheckboxesProps
	| ValidationDateProps
	| ValidationTextProps
	| ValidationTextareaProps
	| ValidationPasswordProps
	| ValidationSingleSelectProps
	| ValidationNumberProps
	| ValidationMultipleSelectProps
	| ValidationMultipleTextProps
	| ValidationRangeSelectProps

const Input = React.memo((props: ValidationInputProps) => {
	const isInvalid = React.useMemo(() => !!props.error, [props.error]);
	return (
		<>
			{props.inputtype === "checkboxes" && <Checkboxes {...props} isInvalid={isInvalid} />}
			{props.inputtype === "radios" && <Radios {...props} isInvalid={isInvalid} />}
			{props.inputtype === "date" && <DateInput {...props} isInvalid={isInvalid} />}
			{props.inputtype === "text" && <Text {...props} isInvalid={isInvalid} />}
			{props.inputtype === "number" && <NumberInput {...props} isInvalid={isInvalid} />}
			{props.inputtype === "textarea" && <Textarea {...props} isInvalid={isInvalid} />}
			{props.inputtype === "password" && <Password {...props} isInvalid={isInvalid} />}
			{props.inputtype === "single-select" && <SingleSelect {...props} isInvalid={isInvalid} />}
			{props.inputtype === "multiple-select" && <MultipleSelect {...props} isInvalid={isInvalid} />}
			{props.inputtype === "multiple-text" && <MultipleText {...props} />}
			{props.inputtype === "range-select" && <RangeSelect {...props} />}
			{props.error && (
				<FormControl.Feedback type={"invalid"} style={{ display: "block" }}>
					{props.error}
				</FormControl.Feedback>
			)}
		</>
	);
});

export const ValidationInput = React.memo((props: ValidationInputProps) => {
	const { noblock, label } = props;
	if (!label) {
		<FormGroup>
			<Input {...props} />
		</FormGroup>;
	}
	if (noblock) {
		return (
			<FormGroup as={Row}>
				<FormLabel column md={2}>
					{props.label}
				</FormLabel>
				<Col md={10}>
					<Input {...props} />
				</Col>
			</FormGroup>
		);
	}
	return (
		<FormGroup>
			<FormLabel>{props.label}</FormLabel>
			<div>
				<Input {...props} />
			</div>
		</FormGroup>
	);
});
