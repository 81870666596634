import * as React from "react";
import { FormControl } from "react-bootstrap";
import { InputBaseProps } from "..";

export type NumberInputProps = {
	onChange: (value: number) => void;
	value: number;
	placeholder?: string;
} & InputBaseProps;

export const NumberInput = React.memo((props: NumberInputProps) => {
	const { onChange, value, ...textProps } = props;
	const onChangeDate = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const tmp = Number(e.target.value);
			onChange(isNaN(tmp) ? undefined : tmp);
		},
		[onChange]
	);
	return (
		<FormControl {...textProps} type={"number"} value={value === undefined ? "" : value} onChange={onChangeDate} />
	);
});
