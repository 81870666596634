import * as React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { AccountIcon, LogoutIcon } from "../icons";

export type AppBarProps = {
	account?: AccountWithoutPassword;
	onLogout?: () => void;
	onHelp?: () => void;
};

export const AppBar = React.memo((props: AppBarProps) => {
	const { onLogout, account } = props;
	return (
		<Navbar className="app-bar" expand="sm" variant="light">
			<Container fluid>
				<Navbar.Brand href="/" className="font-weight-bold">
					{process.env.SYSTEM_NAME}
				</Navbar.Brand>
				<Navbar.Brand className="app-bar__brand">
					{/**<img className="app-bar__brand__image" alt="" src="/images/brand48x48.png" />**/}
					<span className="app-bar__brand__text">{}</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls={"basic-navbar-nav"} />
				<Navbar.Collapse id={"basic-navbar-nav"}>
					<Nav className="mr-auto"></Nav>
					<Nav className="app-bar__right-menu">
						{account && (
							<>
								<Navbar.Text>
									<AccountIcon />
									<span>{account.name}</span>
								</Navbar.Text>
								{onLogout && (
									<Button variant="primary" onClick={onLogout}>
										<LogoutIcon />
									</Button>
								)}
							</>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
});
